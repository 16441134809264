import React, { useEffect, useState } from "react";
import {
  Button,
  Header,
  Icon,
  Label,
  Pagination,
  Search,
  Table,
} from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { Row, WithLoader } from "../common";
import * as UsersService from "../../services/users";
import "../../styles/Users.scss";
import { useDebounce, useRequest } from "../../resources/hooks";
import { UserModal } from ".";
import constants from "../../resources/constants/roles";

const OFFSET_TOP = 170; // px
const ORDER_ROW_HEIGHT = 60; // px
const MAX_USERS_BY_PAGE = Math.floor(
  (window.innerHeight - OFFSET_TOP) / ORDER_ROW_HEIGHT
);

const UsersTable = () => {
  const history = useHistory();

  const [isCreating, setIsCreating] = useState(false);
  const [userId, setUserId] = useState(null);

  const [searchUserLabel, setSearchUserLabel] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const fetchUsers = useRequest({
    service: UsersService.fetchUsers,
    requestOnInit: false,
  });

  useDebounce(searchUserLabel, {
    onTimeout: () => {
      setCurrentPage(1);
      fetchUsers.request({
        params: {
          skip: (1 - 1) * MAX_USERS_BY_PAGE,
          limit: MAX_USERS_BY_PAGE,
          searchText: searchUserLabel,
        },
      });
    },
  });

  useEffect(() => {
    handlePageChange(1);
  }, []);

  const maxPage =
    Math.ceil(fetchUsers.response?.count / MAX_USERS_BY_PAGE) || 1;

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetchUsers.request({
      params: {
        skip: (newPage - 1) * MAX_USERS_BY_PAGE,
        limit: MAX_USERS_BY_PAGE,
        searchText: searchUserLabel,
      },
    });
  };

  const resetTable = () => {
    setSearchUserLabel("");
    setCurrentPage(1);
    fetchUsers.request({
      params: {
        skip: (1 - 1) * MAX_USERS_BY_PAGE,
        limit: MAX_USERS_BY_PAGE,
      },
    });
  };

  const redirectToUserOrders = (userId) => {
    history.push(`/users/${userId}/orders`);
  };

  const users = fetchUsers.response?.users || [];

  return (
    <div id="users">
      <Row className="header" align="center" fluid>
        <Header as="h2">Utilisateurs</Header>

        <Button
          icon="plus"
          color="green"
          content="Créer un nouvel utilisateur"
          style={{ marginLeft: "1rem" }}
          onClick={() => setIsCreating(true)}
        />

        <Search
          className="search-input"
          loading={fetchUsers.loading}
          open={false}
          onSearchChange={(e, { value }) => {
            if (value.length < 3 && searchUserLabel.length > 2) resetTable();
            else setSearchUserLabel(value);
          }}
          minCharacters={3}
          placeholder="Rechercher un client"
          style={{ marginLeft: "auto" }}
        />
      </Row>

      <div className="data-container">
        <WithLoader
          loading={fetchUsers.loading}
          text="Récupération des utilisateurs"
        >
          <Table basic="very">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Nom de famille</Table.HeaderCell>
                <Table.HeaderCell>Prénom</Table.HeaderCell>
                <Table.HeaderCell>Role</Table.HeaderCell>
                <Table.HeaderCell>Téléphone</Table.HeaderCell>
                <Table.HeaderCell>Adresse</Table.HeaderCell>
                <Table.HeaderCell>Actif ?</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {users.map((user) => (
                <Table.Row key={user.id}>
                  <Table.Cell>{user.email}</Table.Cell>
                  <Table.Cell>{user.lastname}</Table.Cell>
                  <Table.Cell>{user.firstname}</Table.Cell>
                  <Table.Cell>
                    <Label
                      basic
                      color={constants.ROLES.hashMap[user.role]?.color}
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {constants.ROLES.hashMap[user.role]?.label}
                    </Label>
                  </Table.Cell>
                  <Table.Cell>{user.phone}</Table.Cell>
                  <Table.Cell>{user.address}</Table.Cell>
                  <Table.Cell>{user.active ? "✅" : "❌"}</Table.Cell>
                  <Table.Cell>
                    <Row>
                      <Button
                        icon
                        color="blue"
                        labelPosition="left"
                        onClick={() => setUserId(user.id)}
                      >
                        <Icon name="edit" />
                        Editer
                      </Button>
                      <Button
                        icon
                        color="violet"
                        labelPosition="left"
                        onClick={() => redirectToUserOrders(user.id)}
                      >
                        <Icon name="list alternate" />
                        Commandes
                      </Button>
                    </Row>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </WithLoader>

        <Pagination
          className="pagination"
          boundaryRange={0}
          onPageChange={(e, { activePage }) => handlePageChange(activePage)}
          activePage={currentPage}
          siblingRange={1}
          totalPages={maxPage}
          pointing
          secondary
          fluid
        />
      </div>

      {(isCreating || userId) && (
        <UserModal
          userId={userId}
          closeModal={({ withReload }) => {
            setIsCreating(null);
            setUserId(null);
            if (withReload) {
              handlePageChange(currentPage);
            }
          }}
        />
      )}
    </div>
  );
};

export default UsersTable;
