import axios from "axios";

axios.defaults.withCredentials = true;
axios.defaults.baseURL =
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_LOCAL_API_URL
    : process.env.REACT_APP_API_URL;

export async function fetchDiscountCodes() {
  const { data } = await axios.get(`/api/discountCodes`);

  return data;
}

export async function getDiscountCodesPaginated({ skip, limit, searchText }) {
  const { data } = await axios.get(`/api/discountCodes/paginated`, {
    params: {
      skip,
      limit,
      searchText,
    },
  });

  return data;
}

export async function fetchDiscountCode({ discountCodeId }) {
  const { data } = await axios.get(`/api/discountCodes/${discountCodeId}`);

  return data;
}

export async function createDiscountCode({
  code,
  expirationDate,
  maximumApplications,
  percentageAmount,
  fixedAmount,
  isActive,
}) {
  const { data } = await axios.post(`/api/discountCodes`, {
    code,
    expirationDate,
    maximumApplications,
    percentageAmount,
    fixedAmount,
    isActive,
  });

  return data;
}

export async function updateDiscountCode({
  discountCodeId,
  code,
  expirationDate,
  maximumApplications,
  percentageAmount,
  fixedAmount,
  isActive,
}) {
  const { data } = await axios.put(`/api/discountCodes/${discountCodeId}`, {
    code,
    expirationDate,
    maximumApplications,
    percentageAmount,
    fixedAmount,
    isActive,
  });

  return data;
}
