import { useEffect, useState } from "react";
import { DateInput3 } from "@blueprintjs/datetime2";
import dayjs from "dayjs";
import { Button, Checkbox, Form, Message, Modal } from "semantic-ui-react";
import { useInput, useRequest } from "../../resources/hooks";
import {
  createDiscountCode,
  fetchDiscountCode,
  updateDiscountCode,
} from "../../services/discountCodes";
import { WithLoader } from "../common";
import { formatToUppercase } from "../../resources/helpers";
import "dayjs/locale/fr";
import "../../styles/DiscountCodes.scss";
import { centimesToAmount } from "../../resources/helpers/PriceHelper";

const DiscountCodeModal = ({
  open,
  closeModal,
  onClose,
  updateId: discountCodeId,
}) => {
  const create = useRequest({
    service: createDiscountCode,
    requestOnInit: false,
  });
  const update = useRequest({
    service: updateDiscountCode,
    requestOnInit: false,
  });
  const get = useRequest({
    service: () => fetchDiscountCode({ discountCodeId }),
    requestOnInit: false,
  });

  const code = useInput(
    "",
    { label: "Code", placeholder: "REDUC77" },
    (raw) => {
      let resultString = raw;
      resultString = resultString.trim().toUpperCase();
      resultString = resultString.replace(/[^a-zA-Z0-9 ]/g, "");
      resultString = resultString
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "");
      return resultString;
    }
  );
  const expirationDate = useInput(null);
  const maximumApplications = useInput(null, {
    label: "Nombre d'utilisation maximum",
    placeholder: "Illimitée",
    type: "number",
    min: 1,
  });
  const discountPercentage = useInput(null, {
    label: "Réduction en pourcentage",
    placeholder: "%",
    type: "number",
    min: 0,
    max: 100,
  });
  const discountAmount = useInput(null, {
    label: "Réduction fixe",
    placeholder: "€",
    type: "number",
    min: 0,
  });
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    if (discountCodeId) {
      get.request();
    }
  }, [discountCodeId]);

  useEffect(() => {
    if (get.response) {
      code.setValue(get.response.code);
      expirationDate.setValue(get.response.expirationDate);
      maximumApplications.setValue(get.response.maximumApplications);
      discountPercentage.setValue(get.response.percentageAmount);
      discountAmount.setValue(
        get.response.fixedAmount
          ? centimesToAmount(get.response.fixedAmount)
          : get.response.fixedAmount
      );
      setIsActive(get.response.isActive);
    }
  }, [get.response]);

  const handleSubmit = async () => {
    code.resetError();
    discountPercentage.resetError();
    discountAmount.resetError();

    let hasErrors = false;
    if (!code.value) {
      hasErrors = true;
      code.setError("Obligatoire");
    } else if (code.value.length < 5) {
      hasErrors = true;
      code.setError("Trop court (minimum: 5)");
    } else if (code.value.length > 10) {
      hasErrors = true;
      code.setError("Trop long (maximum: 10)");
    }
    // TODO: include regex
    else if (code.value.includes(" ")) {
      hasErrors = true;
      code.setError("Caractères interdits");
    }
    if (discountPercentage.value > 0 && discountAmount.value > 0) {
      hasErrors = true;
      discountPercentage.setError(
        "Un seul type de réduction peut être appliqué"
      );
      discountAmount.setError("Un seul type de réduction peut être appliqué");
    } else if (!discountPercentage.value && !discountAmount.value) {
      hasErrors = true;
      discountPercentage.setError("Un type de réduction est requis");
      discountAmount.setError("Un type de réduction est requis");
    }

    if (hasErrors) return;

    if (!discountCodeId) {
      const result = await create.request({
        params: {
          code: code.value.trim(),
          expirationDate: expirationDate.value,
          maximumApplications: maximumApplications.value
            ? Number(maximumApplications.value)
            : undefined,
          percentageAmount: discountPercentage.value
            ? Number(discountPercentage.value)
            : undefined,
          fixedAmount: discountAmount.value
            ? Number(discountAmount.value)
            : undefined,
          isActive,
        },
      });

      if (result.response) closeModal({ withReload: true });
    } else {
      const result = await update.request({
        params: {
          discountCodeId,
          code: code.value,
          expirationDate: expirationDate.value,
          maximumApplications: maximumApplications.value
            ? Number(maximumApplications.value)
            : undefined,
          percentageAmount: discountPercentage.value
            ? Number(discountPercentage.value)
            : undefined,
          fixedAmount: discountAmount.value
            ? Number(discountAmount.value)
            : undefined,
          isActive,
        },
      });

      if (result.response) closeModal({ withReload: true });
    }
  };

  const loading = get.loading || create.loading || update.loading;
  const error = create.error || update.error;

  const isUpdating = Boolean(discountCodeId);

  return (
    <Modal
      open={open}
      onClose={onClose}
      className="discount-code-modal"
      size="tiny"
    >
      <Modal.Header>
        {discountCodeId
          ? `Modification du code ${code.value}`
          : "Nouveau code de réduction"}
      </Modal.Header>

      <Modal.Content>
        <WithLoader modalMode loading={get.loading}>
          <Form>
            {isUpdating ? (
              <Form.Group className="form-row">
                <Form.Field width={16}>
                  <label>Date d'expiration</label>
                  <DateInput3
                    formatDate={(date) =>
                      formatToUppercase(
                        dayjs(date).locale("fr").format("dddd DD MMMM")
                      )
                    }
                    fill
                    todayButtonText="Expire aujourd'hui"
                    clearButtonText="Illimité"
                    highlightCurrentDay
                    minDate={new Date()}
                    placeholder="Aucune"
                    showActionsBar
                    defaultValue={
                      expirationDate.value
                        ? dayjs(expirationDate.value).format("YYYY-MM-DD")
                        : null
                    }
                    onChange={(selectedStringDate) => {
                      if (selectedStringDate) {
                        expirationDate.onChange(
                          dayjs(selectedStringDate).endOf("d")
                        );
                      } else {
                        expirationDate.onChange(null);
                      }
                    }}
                  />
                </Form.Field>

                <Form.Field>
                  <label>Actif ?</label>
                  <Checkbox
                    checked={isActive}
                    onChange={(e, { checked }) => setIsActive(checked)}
                    toggle
                  />
                </Form.Field>
              </Form.Group>
            ) : (
              <>
                <Form.Group className="form-row" widths={6}>
                  <Form.Field width={6}>
                    <Form.Input fluid {...code.props} autoFocus />
                  </Form.Field>

                  <Form.Field>
                    <label>Actif ?</label>
                    <Checkbox
                      checked={isActive}
                      onChange={(e, { checked }) => setIsActive(checked)}
                      toggle
                    />
                  </Form.Field>
                </Form.Group>

                <Form.Group className="form-row">
                  <Form.Field width={16}>
                    <Form.Input {...maximumApplications.props} />
                  </Form.Field>

                  <Form.Field width={16}>
                    <label>Date d'expiration</label>
                    <DateInput3
                      formatDate={(date) =>
                        formatToUppercase(
                          dayjs(date).locale("fr").format("dddd DD MMMM")
                        )
                      }
                      fill
                      todayButtonText="Expire aujourd'hui"
                      clearButtonText="Illimité"
                      highlightCurrentDay
                      minDate={new Date()}
                      placeholder="Aucune"
                      showActionsBar
                      defaultValue={
                        expirationDate.value
                          ? dayjs(expirationDate.value).format("YYYY-MM-DD")
                          : null
                      }
                      onChange={(selectedStringDate) => {
                        if (selectedStringDate) {
                          expirationDate.onChange(
                            dayjs(selectedStringDate).endOf("d")
                          );
                        } else {
                          expirationDate.onChange(null);
                        }
                      }}
                    />
                  </Form.Field>
                </Form.Group>
              </>
            )}

            <Form.Group className="form-row">
              <Form.Field width={16}>
                <Form.Input fluid {...discountPercentage.props} />
              </Form.Field>

              <Form.Field width={16}>
                <Form.Input fluid {...discountAmount.props} />
              </Form.Field>
            </Form.Group>
          </Form>
        </WithLoader>

        {error && (
          <Message negative>
            <Message.Header>Oups</Message.Header>
            <p>{error}</p>
          </Message>
        )}
      </Modal.Content>

      <Modal.Actions>
        <Button
          color="black"
          onClick={closeModal}
          loading={loading}
          disabled={loading}
        >
          Retour
        </Button>
        <Button
          content={discountCodeId ? "Mettre à jour" : "Créer"}
          labelPosition="right"
          icon="checkmark"
          loading={loading}
          disabled={loading}
          onClick={handleSubmit}
          positive
        />
      </Modal.Actions>
    </Modal>
  );
};

export default DiscountCodeModal;
